/**
 * Created by jerry on 2020/03/5.
 * 时效卡分类api
 */
import * as API from '@/api/index'

export default {
    // 时效分类列表
    getTimeCardCategory: params => {
        return API.POST('api/timeCardCategory/all', params)
    },
    //新增时效卡分类
    createTimeCardCategory: params => {
        return API.POST('api/timeCardCategory/create', params)
    },
    //编辑时效卡分类
    updateTimeCardCategory:params => {
        return API.POST('api/timeCardCategory/update', params)
    },
    //时效卡分类顺序调整
    moveTimeCardCategory:params => {
        return API.POST('api/timeCardCategory/move', params)
    },

}